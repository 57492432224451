















































import Axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class FeedbackSubmit extends Vue {
  valid = false;
  isLoading = false;

  titleRules = [
    (v: any) => !!v || "Title is required",
    (v: string | any[]) =>
      (v && v.length <= 100) || "Title must be less than 100 characters",
  ];

  contentRules = [
    (v: any) => !!v || "Describe is required",
    (v: string | any[]) =>
      (v && v.length <= 5000) || "Describe must be less than 2000 characters",
  ];

  form = {
    qqId: -1,
    title: "",
    content: "",
  };

  commitForm() {
    const _this = this;
    _this.isLoading = true;
    (_this.$refs as any).form.validate();
    Axios({
      url: "/feedback/exception",
      method: "post",
      data: this.form,
    })
      .then(function (response) {
        _this.isLoading = false;
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }
}
